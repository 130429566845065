var fb =
{
data: {
	ads: null,
	adBlocks: {
		sidebar: { c: 'myh-v', m: 'myh-mob', b: 'dna-v' },
		horizontal: { c: 'myh-h', m: 'myh-mob', b: 'dna-h' }
	},
	toggleHTML: false,
	typheadStorage: {}
},
lang: {
	available: ['de','en','es','fr','it','nl','pt','ru','sh'],
	de:
	{
		fn: 'Vorname',
		loggedIn: 'Erfolgreich angemeldet',
		lsg: 'Diese Seite ist ebenfalls verfügbar auf',
		sn: 'Nachname',
		kws: 'genealogie-namen-nachnamen',
		menu: 'Menü',
		
		desc: 'Beschreibung',
		email: 'E-Mail',
		forgot: 'Passwort vergessen?',
		ident: 'Benutzername oder E-Mail',
		login: 'Einloggen',
		loginReg: 'Um Informationen zu diesem Nachnamen einzureichen, müssen Sie angemeldet sein',
		password: 'Passwort',
		register: 'Registrieren',
		remember: 'Angemeldet bleiben',
		selectVars: 'Namensvarianten auswählen . . .',
		username: 'Benutzername',
		vars: 'Namensvarianten',
		submit: 'Einreichen'
	},
	en:
	{
		fn: 'forename',
		lang: 'English',
		loggedIn: 'Logged in successfully',
		lsg: 'This page is also available in',
		sn: 'surname',
		kws: 'genealogy-names-surnames',
		menu: 'Menu',
		
		desc: 'Description',
		email: 'Email',
		forgot: 'Forgot password?',
		ident: 'Username or email',
		login: 'Login',
		loginReg: 'To submit information on this surname you must be logged in',
		password: 'Password',
		register: 'Register',
		remember: 'Remember me',
		selectVars: 'Select name variants . . .',
		username: 'Username',
		vars: 'Name Variants',
		submit: 'Submit'
	},
	es:
	{
		fn: 'nombre de pila',
		loggedIn: 'Se registró con éxito',
		lsg: 'Esta página también está disponible en',
		sn: 'apellido',
		kws: 'genealogía-nombres-apellidos',
		menu: 'Menú',
		
		desc: 'Descripción',
		email: 'Correo electrónico',
		forgot: '¿Olvidó la contraseña?',
		ident: 'Nombre de usuario o correo electrónico',
		login: 'Inicio de Sesión',
		loginReg: 'Para presentar la información sobre este apellido debe haber iniciado sesión',
		password: 'Contraseña',
		register: 'Registrarse',
		remember: 'Recordarme',
		selectVars: 'Seleccione las variantes del nombre . . .',
		username: 'Nombre de Usuario',
		vars: 'Variantes del Nombre',
		submit: 'Enviar'
	},
	fr:
	{
		fn: 'prénom',
		lang: 'Français',
		loggedIn: 'Connexion réussie',
		lsg: 'Cette page est également disponible en',
		sn: 'nom de famille',
		kws: 'généalogie-noms-famille',
		menu: 'Menu',
		
		desc: 'Description',
		email: 'Email',
		forgot: 'Mot de pass eoublié ?',
		ident: 'Nom d\'utilisateur ou email',
		login: 'Connexion',
		loginReg: 'Pour soumettre des informations sur ce nom de famille, vous devez être connecté',
		password: 'Mot de passe',
		register: 'S\'inscrire',
		remember: 'Se rappeler de moi',
		selectVars: 'Sélectionnez la variante du noms . . .',
		username: 'Nom d\'utilisateur',
		vars: 'Variantes de noms',
		submit: 'Soumettre'
	},
	it:
	{
		fn: 'primo nome',
		lang: 'Italiano',
		loggedIn: 'Accesso eseguito correttamente',
		lsg: 'Questa pagina è disponibile anche in',
		sn: 'cognome',
		kws: 'genealogia-nomi-cognomi',
		menu: 'Menu',
		
		desc: 'Descrizione',
		email: 'E-mail',
		forgot: 'Password dimenticata?',
		ident: 'Nome utente o email',
		login: 'Accesso',
		loginReg: 'Per inviare le informazioni su questo cognome devi aver effettuato l\'accesso',
		password: 'Password',
		register: 'Registrati',
		remember: 'Ricordami',
		selectVars: 'Seleziona le varianti del nome . . .',
		username: 'Nome utente',
		vars: 'Varianti del nome',
		submit: 'Invia'
	},
	nl:
	{
		fn: 'voornaam',
		lang: 'Nederlands',
		loggedIn: 'Succesvol ingelogd',
		lsg: 'Deze pagina is ook beschikbaar in',
		sn: 'achternaam',
		kws: 'genealogie-namen-achternamen',
		menu: 'Menu',
		
		desc: 'Omschrijving',
		email: 'E-mail',
		forgot: 'Wachtwoord vergeten?',
		ident: 'Gebruikersnaam of E-mail',
		login: 'Inloggen',
		loginReg: 'Om informatie over deze achternaam in te dienen moet u ingelogd zijn',
		password: 'Wachtwoord',
		register: 'Registreren',
		remember: 'Mij herinnerene',
		selectVars: 'Selecteer naamvarianten . . .',
		username: 'Gebruikersnaam',
		vars: 'Naamvarianten',
		submit: 'Versturen'
	},
	pt:
	{
		fn: 'nome próprio',
		lang: 'Português',
		loggedIn: 'Sessão iniciada com êxito',
		lsg: 'Esta página está também disponível em',
		sn: 'sobrenome',
		kws: 'genealogia-nomes-sobrenomes',
		menu: 'Menu',
		
		desc: 'Descrição',
		email: 'Email',
		forgot: 'Esqueceu-se da palavra-passe?',
		ident: 'Nome de utilizador ou email',
		login: 'Iniciar sessão',
		loginReg: 'Para enviar informações sobre este apelido deve iniciar sessão',
		password: 'Palavra-passe',
		register: 'Registar',
		remember: 'Lembre-me',
		selectVars: 'Selecionar variantes de nomes . . .',
		username: 'Nome de utilizador',
		vars: 'Variantes de nomes',
		submit: 'Enviar'
	},
	ru:
	{
		fn: 'Имя',
		lang: 'русский',
		loggedIn: 'Вы успешно вошли',
		lsg: 'Эта страница также доступна в',
		sn: 'фамилия',
		kws: 'генеалогия-имена-фамилии',
		menu: 'Меню',
		
		desc: 'Описание',
		email: 'Электронная почта',
		forgot: 'Забыли пароль?',
		ident: 'Имя пользователя или адрес электронной почты',
		login: 'Логин',
		loginReg: 'Чтобы предоставить информацию об этой фамилии, вы должны войти в систему',
		password: 'Пароль',
		register: 'Зарегистрироваться',
		remember: 'Запомнить меня',
		selectVars: 'Выбрать варианты имени. . . .',
		username: 'Имя пользователя',
		vars: 'Варианты имен',
		submit: 'Отправить'
	},
	sh:
	{
		fn: 'ime',
		lang: 'srpskohrvatski',
		loggedIn: 'Uspešno ste se prijavili',
		lsg: 'Ova stranica je dostupna i na', 
		sn: 'prezime',
		kws: 'genealogija-imena-prezimena',
		menu: 'Meni',
		
		desc: 'Opis', 
		email: 'Imejl', 
		forgot: 'Zaboravili ste lozinku?', 
		ident: 'Korisničko ime ili imejl', 
		login: 'Prijavljivanje', 
		loginReg: 'Da biste dodali informacije o ovom prezimenu morate biti prijavljeni', 
		password: 'Lozinka', 
		register: 'Registracija', 
		remember: 'Zapamti me', 
		selectVars: 'Izaberite varijante imena . . .', 
		username: 'Korisničko ime', 
		vars: 'Varijante imena',
		submit: 'Obrazac'
	}
},

initialiseAccount: function()
{
	if( fb.getCookie( 'u' ) != '' )
	{
		$( '.nav-item-login, .nav-item-register, .list-separator' ).hide().removeClass( 'nav-display' );
		$( '.nav-item-account' ).show().addClass( 'nav-display' );
		$( '.action-account' ).hide();
	}
	else
	{
		$( '.nav-item-login, .nav-item-register, .list-separator' ).show().addClass( 'nav-display' );
		$( '.nav-item-account' ).hide().removeClass( 'nav-display' );
		
		if( fb.getCookie( 'r' ) != '' )
		{
			$.ajax(
			{
				method: 'GET',
				url: 'a?c=Account&m=remember',
				dataType: 'json',
				success: function( response )
				{
					if( response == true )
						fb.initialiseAccount();
				}
			});
		}
	}
},

initialiseCitations: function()
{
	$( 'sup[data-toggle]' ).each( function()
	{
		var sup = $( this );
		var id = sup.attr( 'id' );
		var li = $( '#' + id.replace( 'sup', 'ref' ) );
		sup.mouseover( function()
		{
			sup.attr( 'data-original-title', $( '#' + id.replace( 'sup', 'ref' ) ).text() );
		}).click( function()
		{
			$( 'html, body' ).stop().animate( { scrollTop: li.offset().top - ( $( 'nav.top-nav' ).outerHeight() + 50 ) }, 500 );
		});
		li.click( function( e )
		{
			if ( e.offsetX < 0 )
				$( 'html, body' ).stop().animate( { scrollTop: sup.offset().top - ( $( 'nav.top-nav' ).outerHeight() + 50 ) }, 500 );
		});
	});
},

initialiseEmailSubscribe: function()
{
	$( '#email-subscribe' ).on( 'submit', function( e )
	{
		e.preventDefault();
		var form = $( this );
		var values = form.serializeArray();
		var data = {};
		$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
		$.ajax(
		{
			method: 'POST',
			url: 'a?c=Email&m=add',
			dataType: 'json',
			data: data,
			success: function( response )
			{
				alert( response.message );
				if( response.status == 'success' )
					$( '#email-subscribe' ).remove();
			},
			error: function()
			{
				alert( 'There was an unexpected error. Please try again.' );
			}
		});
	});
},

initialiseLanguageSelection: function()
{
	if( $( '#multi' ).length == 0 ) return false;
	var qs = window.location.search;
	$( '.language-dropdown' ).append( '<button id="language-dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button><div class="dropdown-menu" aria-labelledby="language-dropdown-toggle"></div>' );
	var parent = $( '.language-dropdown .dropdown-menu' );
	var siteLang = fb.getSiteLanguage();
	var currentIco = fb.getIcoFromLanguage( siteLang );
	$( '#language-dropdown-toggle' ).append( '<svg class="flag-icon"><use href="/assets/img.svg#' + currentIco + '"></use></svg>' + siteLang );
	var urlParts = window.location.pathname.split( '/' );
	if( typeof urlParts[2] != 'undefined' && $.inArray( urlParts[2], fb.lang.available ) != -1 ) delete urlParts.splice( 2, 1 );
	if( typeof urlParts[1] != 'undefined' && ( urlParts[1] == 'x' || $.inArray( urlParts[1], fb.lang.available ) != -1 ) ) delete urlParts.splice( 1, 1 );
	var url = urlParts.join( '/' );
	
	fb.lang.available.forEach( function( lang )
	{
		if( siteLang == lang ) return;
		langDir = ( lang == 'en' ) ? ( urlParts.length == 1 ) ? '/' : '' : '/' + lang;
		var langIco = fb.getIcoFromLanguage( lang );
		var el = $( '<a href="' + langDir + url + qs + '" class="dropdown-item"><svg class="flag-icon"><use href="/assets/img.svg#' + langIco + '"></use></svg>' + lang + '</a>' );
		parent.append( el );
	});
},

initialiseLayout: function()
{
	$( '.breadcrumbs' ).each( function()
	{
		$( this )[0].scrollLeft = $( this )[0].scrollWidth;
	});
	
	$( '.nav-toggle' ).on( 'click', function( e )
	{
		e.preventDefault();
		$( 'nav.top-nav' ).toggleClass( 'menu-show' );
		if( $( 'nav.top-nav' ).hasClass( 'menu-show' ))
			$(".navigation-responsive").css( { display: 'block', opacity: 1, translateY: -10 } );
		else
			$(".navigation-responsive").css( { display: 'none', opacity: 0, translateY: -10 } );
	});
	$( window ).on( 'scroll', function()
	{
		var st = $( window ).scrollTop();
			if( st < 100 )
				$( 'nav.top-nav' ).removeClass( 'nav-scrolled' );
		else
			$( 'nav.top-nav' ).addClass( 'nav-scrolled' );        
	});
	if( $( '.nav-spacing-helper' ).length )
	{
		var p = $( '.nav-spacing-helper' ).css( 'padding-top' ).replace( 'px', '' );
		$( '.nav-spacing-helper' ).css( 'padding-top', ( parseInt( p ) + $( 'nav.top-nav' ).outerHeight() ) + 'px' );
	}
	else
	{
		$( 'nav.top-nav' ).addClass( 'nav-separate' );
		var height = $( 'nav.top-nav' ).outerHeight();
		if( height > 101 ) height = 100.75;
		$( 'body' ).css( 'padding-top', height + 'px' );
	}
	$( '.login-dropdown .dropdown-menu' ).click( function( e )
	{
		e.stopPropagation();
	});
	
	function bodyClickDropdown( e )
	{
		if( !$( e.target ).parents( '.sidebar' ).length )
		{
			$( '.sidebar' ).removeClass( 'sidebar-show' );
			$( 'body' ).off( 'click', bodyClickDropdown );     
		}
	}
	var sidebar = $( '.sidebar' );
	var toggle = '<button class="btn btn-sidebar-toggle">' + fb.lang[fb.getSiteLanguage()].menu + ' <i class="fb fb-list"></i></button>';
	if( sidebar.length )
	{
		sidebar.wrapInner( '<div class="sidebar-inner"></div>' );
		sidebar.prepend( toggle );
	}
	$( '.btn-sidebar-toggle' ).on( 'click', function( e )
	{
		e.preventDefault();
		$( '.sidebar' ).toggleClass( 'sidebar-show' );
		if( $( '.sidebar' ).hasClass( 'sidebar-show' ) )
		{
			$('.sidebar .sidebar-inner').css( { display: 'block', opacity: 1, translateY: -10 } );
			$( 'body' ).on( 'click', bodyClickDropdown );
		}
		else
		{
			$('.sidebar .sidebar-inner').css( { display: 'none', opacity: 0, translateY: -10 } );
			$( 'body' ).off( 'click', bodyClickDropdown );
		}
	});
	if( $.inArray( window.location.pathname.toString().toLowerCase().substring( 0, 10 ), ['/forenames/','/surnames/'] ) !== -1 )
		$( '.sidebar-group' ).css( 'min-height', '250px' );
	$( '.sidebar' ).each( function()
	{
		var mainContent = $( this ).next( '.container' );
		if( $( this ).height() > mainContent.height() )
			mainContent.height( $( this ).height() )
	});
	$( '.sidebar[data-sidebar-point]' ).each( function()
	{
		var el = $( $( this ).attr( 'data-sidebar-point' ) );
		$( this ).css( 'top', ( el.offset().top - $( this ).offset().top ) + 'px' );
		$( '.sidebar' ).show();
	});
	
	$( '[data-hint]' ).each( function()
	{
        var icon = "<i class='icon fb fb-info'  data-toggle='tooltip' data-placement='top' title='" + $( this ).attr( 'data-hint' ) + "'></i>";
        $( this ).prepend( icon );
    });
    $( '[data-toggle="tooltip"]' ).tooltip();
},

initialiseLightbox: function()
{
	var imgs = $( 'a.strip' );
	if( imgs.length > 0 )
	{
		$( 'head' ).append( '<link rel="stylesheet" href="/assets/lightbox.css" type="text/css" />' );
		$( 'head' ).append( '<script type="text/javascript" src="/assets/lightbox.js"></script>' );
		$( 'a.strip' ).simpleLightbox();
	}
},

initialiseLogin: function()
{
	var loaders = fb.generateLoaders( fb.lang[fb.getSiteLanguage()].loggedIn );
	$( '.login-form' ).after( loaders ).on( 'submit', function( e )
	{
		e.preventDefault();
		var form = $( this );
		var values = form.serializeArray();
		var data = {};
		$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
		data.login = 1;
		var formParent = form.parents( '.form-parent' );
		formParent.addClass( 'loading' );
		$.ajax(
		{
			method: 'POST',
			url: 'a?c=Account&m=login',
			dataType: 'json',
			data: data,
			success: function( response )
			{
				formParent.removeClass( 'loading' );
				if( response.status == 'error' )
				{
					formParent.addClass( 'error' );
					var errorEl = formParent.find( '.ajax-error .content' );
					$.each( response.errors, function( index, error )
					{
						errorEl.append( '<p>' + error + '</p>' );
					});
					errorEl.append( '<button class="btn btn-primary btn-label" onclick="fb.closeAccountMessage( event )">Close</button>' );
				}
				else
				{
					formParent.addClass( 'success' );
					if( typeof fav !== 'undefined' )
					{
						fav.state.loggedIn = true;
						$( '#login-prompt' ).fadeOut( 200 );
						fav.listGet();
					}
					if( fb.storageAvailable() )
						localStorage.setItem( 'username', response.user.username );
					setTimeout( function() { formParent.removeClass('success'); fb.initialiseAccount(); }, 2000 );
				}
			},
			error: function( err )
			{
				formParent.removeClass( 'loading' );
				formParent.addClass( 'error' );
				var errorEl = formParent.find( '.ajax-error .content' );
				errorEl.append( '<p>There was an unexpected error</p>' );
				setTimeout( function() { formParent.removeClass( 'error' ); errorEl.empty(); }, 2000 );
			}
		})
	});
	
	$( '.btn-submission-box-toggle' ).on( 'click', function() { $( this ).remove(); $( '.account-actions-wrapper' ).show(); });
},

initialiseModals: function()
{
	$( '*[data-modal-src]' ).click( function()
	{
		var url = $( this ).attr( 'data-modal-src' );
		var data = $( this ).attr( 'data-modal-data' );
		if( !url ) return false;
		var parts = url.split( '/' );
		var id = 'modal' + parts[parts.length - 1].replace( '.html', '' );
		var modal = '<div class="modal fade" id="' + id + '" role="dialog" aria-hidden="true"><div class="modal-dialog" role="document"><div class="modal-content"></div></div></div>';
		$( 'body' ).append( modal );
		$( '#' + id + ' .modal-content' ).load( window.location.href.split( '/' )[0] + '/data/tpl/' + url, function( result )
		{
			if( data )
			{
				$.each( JSON.parse( data ), function( key, value )
				{
					$( '#' + id + ' *[data-modal-' + key + ']' ).val( value );
				});
			}
			$( '#' + id ).modal();
		});
	});
},

initialiseOutLinks: function()
{
	$( 'a[href^="o?"]' ).on( 'click', function( e )
	{
		e.preventDefault();
		var url = $( this ).attr( 'href' );
		if( fb.storageAvailable() )
		{
			var ref = localStorage.getItem( 'http-referrer' );
			if( ref ) url += '&r=' + ref;
		}
		
		window.location = window.location.protocol + '//' + window.location.host + '/' + url;
	});
},

initialiseRegister: function()
{
	var loaders = fb.generateLoaders( 'Your account has been registered' );
	$( '.register-form' ).after( loaders ).on( 'submit', function( e )
	{
		e.preventDefault();
		var form = $( this );
		var values = form.serializeArray();
		var data = {};
		$( values ).each( function( index, obj ) { data[obj.name] = obj.value; } );
		data.register = 1;
		var formParent = form.parents( '.form-parent' );
		formParent.addClass( 'loading' );
		$.ajax(
		{
			method: 'POST',
			url: 'a?c=Account&m=register',
			dataType: 'json',
			data: data,
			success: function( response )
			{
				formParent.removeClass( 'loading' );
				if( response.status == 'error' )
				{
					formParent.addClass( 'error' );
					var errorEl = formParent.find( '.ajax-error .content' );
					$.each( response.errors, function( index, error )
					{
						errorEl.append( '<p>' + error + '</p>' );
					});
					errorEl.append( '<button class="btn btn-primary btn-label" onclick="fb.closeAccountMessage( event )">Close</button>' );
				}
				else
				{
					formParent.addClass( 'success' );
					if( typeof fav !== 'undefined' )
						fav.state.loggedIn = true;
					if( fb.storageAvailable() )
						localStorage.setItem( 'username', response.user.username );
					setTimeout( function() { formParent.removeClass( 'success' ); fb.initialiseAccount(); $( '.submission-form' ).show(); }, 2000 );
				}
			},
			error: function()
			{
				formParent.removeClass( 'loading' );
				formParent.addClass( 'error' );
				var errorEl = formParent.find( '.ajax-error .content' );
				errorEl.append( '<p>There was an unexpected error</p>' );
				setTimeout( function() { formParent.removeClass( 'error' ); errorEl.empty(); }, 2000 );
			}
		});
	});
},

initialiseSearch: function()
{
	$( 'form[action="search"]' ).each( function( i, el )
	{
		$( el ).append( '<input type="hidden" name="meaning" value="' + fb.lang[fb.getSiteLanguage()].kws + '" />' );
	});
},

initialiseStorage: function()
{
	if( fb.storageAvailable() && !localStorage.getItem( 'http-referrer' ) )
	{
		if( document.referrer.length > 10 && !new RegExp( '^(http|https)://forebears' ).test( document.referrer ) )
			localStorage.setItem( 'http-referrer', document.referrer );
	}
},

insertLoginAndRegistrationForms: function( holder, title )
{
	var sl = fb.getSiteLanguage();
	var form = '<div class="account-actions-wrapper"><div class="action-account"><p>' + title + '.</p><div class="row"><div class="col-lg-6"><div class="login-form-wrapper form-parent"><h4 class="mobile-only">' + fb.lang[sl].login + '</h4><form action="" class="login-form">';
	form += '<input type="text" name="ident" autocapitalize="off" class="form-control form-control-input" placeholder="' + fb.lang[sl].ident + '" />';
	form += '<input type="password" name="password" class="form-control form-control-input" placeholder="' + fb.lang[sl].password + '" />';
	form += '<div class="form-control form-control-checkbox"><input type="checkbox" name="remember" class="checkbox" id="remember1" value="1" /><label for="remember1">' + fb.lang[sl].remember + '</label>';
	form += '</div><button type="submit" name="login" value="1"  class="btn btn-primary btn-wide"><i class="icon fb fb-login"></i>' + fb.lang[sl].login + '</button>';
	form += '<div class="small-text text-center"><a class="forgot-password" href="login#forgot">' + fb.lang[sl].forgot + '</a></div></form></div></div>';
	form += '<div class="col-lg-6"><div class="register-form-wrapper form-parent"><form action="" class="register-form"><h4 class="mobile-only">' + fb.lang[sl].register + '</h4>';
	form += '<input type="text" name="username" autocapitalize="off" class="form-control form-control-input" placeholder="' + fb.lang[sl].username + '" />';
	form += '<input type="email" name="email" autocapitalize="off" class="form-control form-control-input" placeholder="' + fb.lang[sl].email + '" />';
	form += '<input type="password" name="password" class="form-control form-control-input" placeholder="' + fb.lang[sl].password + '" />';
	form += '<button name="register" value="1" class="btn btn-primary btn-wide"><i class="icon fb fb-register"></i>' + fb.lang[sl].register + '</button></form></div></div></div></div>';
	$( holder ).append( form );
},

initialiseSurnameSubmission: function()
{
	if( $( '.submission-holder' ).length < 1 ) return false;
	var parts = location.pathname.split( '/' );
	if( typeof parts[1] !== 'undefined' && !parts[1] ) return false;
	var nameType = parts[parts.length - 2].substring( 0, parts[parts.length - 2].length - 1 );
	fb.insertLoginAndRegistrationForms( '.submission-holder', fb.lang[fb.getSiteLanguage()].loginReg );
	var sl = fb.getSiteLanguage();
	var submissionForm = '<div class="form-parent"><form class="submission-form"><label for="">' + fb.lang[sl].vars + '</label><input type="tags" name="vars" placeholder="' + fb.lang[sl].selectVars + '" class="form-control" /><label for="">' + fb.lang[sl].desc + '</label><div id="pell" class="pell-input"></div><div class="text-center"><input type="submit" class="btn btn-primary btn-small btn-submit" value="' + fb.lang[sl].submit + '" /></div><div class="ajax-loader-wrapper"><div class="loader"></div></div><div class="ajax-success ajax-message"><div class="content"></div></div><div class="ajax-error ajax-message"><div class="content"></div></div></form></div></div>';
	$( '.submission-holder' ).append( submissionForm );
	
	// When the submit button is clicked, check if user is really logged in
	$( '.btn-submission-box-toggle' ).on( 'click', function()
	{
		$.ajax(
		{
			method: 'GET',
			url: 'a?c=Account&m=validate',
			dataType: 'json',
			success: function( response )
			{
				if( response.logged == 1 )
				{
					$( '.fs-sticky-footer' ).hide();
					$( '.orp-light-player-wrapper' ).hide();
					//$( '.orp-player-wrapper orp-player-ivm-BR' ).hide();
					$( '.submission-form' ).show();
					$( '.action-account' ).hide();
				}
				else
				{
					$( '.submission-form' ).hide();
					$( '.action-account' ).show();
				}
			}
		});
	});
	
	$( document ).ready( function()
	{
		if( $( '#pell' ).length )
		{
			fb.editor = window.pell.init(
			{
				element: $( '#pell' )[0],
				defaultParagraphSeparator: 'p',
				onChange: function( html ) {},
				actions: ['bold','italic','link','ulist','quote', {
					name: 'htmltoggle',
					icon: 'HTML',
					title: 'Toggle HTML View',
					result: function()
					{
						if( fb.data.toggleHtml )
							fb.editor.content.innerHTML = fb.editor.content.innerText;
						else
							fb.editor.content.innerText = fb.editor.content.innerHTML;
						fb.data.toggleHtml = !fb.data.toggleHtml;
					}
				}]
			});
		}
	});
	
	var url = window.location.pathname.toString().toLowerCase();
	var type = ( url.indexOf( '/surnames/' ) !== -1 ) ? 's' : 'f';
	$( 'input[type="tags"]' ).tagsinput(
	{
		typeaheadjs: [{
			minLength: 1,
			highlight: true
		},
		{
			limit: 99,
			name: type,
			displayKey: 1,
			valueKey: 'name',
			source: fb.typheadSource,
			templates: { suggestion: fb.typeheadTagsSuggeestion }
		}],
		freeInput: true
	});
	
	var loaders = fb.generateLoaders( '' );
	$( '.submission-form' ).on( 'submit', function( e )
	{
			e.preventDefault();
			$( '.btn-submit' ).attr( 'disabled', 'disabled' );
			var form = $( this );
			var formParent = form.parents( '.form-parent' );
			
			var data = {
				id: $( '*[data-sid]' ).attr( 'data-sid' ),
				vars: $( this ).find( '[type="tags"]' ).val(),
				text: fb.editor.content.innerHTML,
				iso: fb.getSiteLanguage(),
				type: nameType
			}
			formParent.addClass( 'loading' );
			$.ajax(
			{
				type: 'POST',
				url: 'a?c=NameUserDescriptionSubmit',
				data: data,
				dataType: 'json',
				success: function( response )
				{
					formParent.removeClass( 'loading' );
					if( response.status == 'error' )
					{
						$( '.btn-submit' ).removeAttr( 'disabled' );
						formParent.addClass( 'error' );
						var errorEl = formParent.find( '.ajax-error .content' );
						$.each( response.errors, function( index, error )
						{
							errorEl.append( '<p>' + error + '</p>' );
						});
						errorEl.append( '<button class="btn btn-primary btn-label" onclick="fb.closeAccountMessage( event )">Close</button>' );
					}
					else
					{
						formParent.addClass( 'success' );
						if( fb.storageAvailable() )
							localStorage.setItem( 'username', $( '#username' ).val() );
						formParent.find( '.ajax-success .content' ).empty().append( '<p>' + response.msg + '</p>' );
						setTimeout( function() { form.parents( '.submission-holder' ).empty().append( response.sanitised ); formParent.removeClass( 'success' ); $( '.account-actions-wrapper' ).remove(); }, 3000 );
					}
				},
				error: function()
				{
					formParent.removeClass( 'loading' );
					formParent.addClass( 'error' );
					$( '.btn-submit' ).removeAttr( 'disabled' );
					setTimeout( function() { formParent.removeClass( 'error' ); }, 2000 );
				}
			})
	});
},

initialiseTextareas: function()
{
	var textarea = $( 'textarea' );
	var heightLimit = 140;
	textarea.on( 'input', function()
	{
		$( this )[0].style.height = '';
		$( this )[0].style.height = Math.max( $( this )[0].scrollHeight, heightLimit ) + 'px';
	});
},

initialiseTranslationSuggestion: function()
{
	if( $( '#distribution' ).length < 1 ) return false;
	if( fb.storageAvailable() && ( localStorage.getItem( 'hide-translation-suggestion' ) == 'true' || localStorage.getItem( 'preferred-language' ) !== null ) ) return false;
	var siteLang = fb.getSiteLanguage();
	var userLang = fb.getUserLanguage();
	if( siteLang == userLang ) return false;
	if( $.inArray( userLang, fb.lang.available ) == -1 ) return false;
	var flag = ( userLang == 'sh' ) ? 'rs' : userLang;
	var flag = ( flag == 'en' ) ? 'gb-eng' : flag;
	var redirect = ( siteLang == 'en' ) ? window.location.pathname.substring( 1 ) : window.location.pathname.replace( /^\/[A-Za-z]{2}\//, '' );
	var redirect = ( userLang == 'en' ) ? redirect : userLang + '/' + redirect;
	var suggestion = $( '<div class="translation-suggestion"><svg class="flag-icon"><use href="/assets/img.svg#' + flag + '"></use></svg>' + fb.lang[userLang].lsg + ' <a class="text-link-normal lang-toggle" href="/' + redirect + '">' + fb.lang[userLang].lang + '</a><button class="btn-hide-suggestion"><i class="fb fb-close"></i></button></div>' );
	suggestion.insertAfter( 'nav' );
	$( '.btn-hide-suggestion' ).on( 'click', function()
	{
		$( '.translation-suggestion' ).remove();
		if( fb.storageAvailable() )
			localStorage.setItem( 'hide-translation-suggestion', true );
	});
	$( '.lang-toggle' ).on( 'click', function( e )
	{
		e.preventDefault();
		if( fb.storageAvailable() )
			localStorage.setItem( 'preferred-language', userLang );
		window.location = $( '.lang-toggle' ).attr( 'href' );
	});
},

initialiseTypheads: function( reload )
{
	reload = typeof reload === 'undefined' ? false : reload;
	
	$( '*[data-typehead]:not([data-parsed]):not([data-ignore])' ).each( function( index )
	{
		var el = $( this );
		var type = $( this ).attr( 'data-typehead' );
		var templateAttr = $( this ).attr( 'data-typehead-template' );
		var template = ( templateAttr ) ? window[templateAttr.split( '.' )[0]][templateAttr.split( '.' )[1]] : fb.typeheadSuggeestion;
		
		el.attr( 'data-parsed', 'yes' );
		el.typeahead(
			{ highlight: true },
			{
				displayKey: 1,
				limit: 99,
				name: type,
				source: fb.typheadSource,
				templates: { suggestion: template }
			}
		);
	});
	
	if( reload == false )
	{
		$( 'input[data-typehead]' ).focus( function()
		{
			if( fb.getDeviceType() == 'm' && $( '#mobile-search' ).length == 0 )
			{
				$( '.top-nav' ).prepend( '<div id="mobile-search"><form action="search" method="get"><div class="close"><i class="icon fb fb-close"></i></div><input type="text" name="q" data-typehead="a" placeholder="Enter a search term" /><div class="search"><button type="submit"><i class="icon fb fb-search"></i></button></div></form></div>' );
				fb.initialiseTypheads( true );
				$( '#mobile-search .close' ).click(function() { $( '#mobile-search' ).remove(); });
				setTimeout(function() { $( '#mobile-search input' ).focus(); }, 10);
			}
		});
	}
},

closeAccountMessage: function( e )
{
	var el = $( e.target );
	el.parents( '.form-parent' ).removeClass( 'error' );
	el.parents( '.content' ).empty();
},

searchDropdown: function( el, target )
{
	var options = $( '#' + target + ' .dropdown-item' );
	var val = $( el ).val().toLowerCase();
	setTimeout( function() {
		options.each( function( i, opt ) {
			var optVal = opt.textContent.toLowerCase();
			if( optVal.startsWith( val ) )
				$( opt ).show();
			else
				$( opt ).hide();
		});
	}, 100 );
},

generateLoaders: function( successMsg )
{
	var loaders = '<div class="ajax-loader-wrapper"><div class="loader"></div></div>';
	loaders += '<div class="ajax-success ajax-message"><div class="content"><p>' + successMsg + '</p></div></div>';
	loaders += '<div class="ajax-error ajax-message"><div class="content"></div></div>';
	return loaders;
},

getCookie: function( cname )
{
    var name = cname + '=';
    var ca = document.cookie.split( ';' );
    for( var i = 0; i < ca.length; i++ )
    {
        var c = ca[i];
        while( c.charAt(0) == ' ' )
        {
            c = c.substring(1);
        }
        if( c.indexOf( name ) == 0 )
        {
            return c.substring( name.length, c.length );
        }
    }
    return '';
},

getIcoFromLanguage: function( lang )
{
	if( lang == 'en' )
		return 'gb-eng';
	else if( lang == 'sh' )
		return 'rs';
	else
		return lang;
},

getSiteLanguage: function()
{
	var parts = location.pathname.split( '/' );
	var part = ( parts[1] == 'x' ) ? parts[2] : parts[1];
	return ( part.length == 2 ) ? part.toLowerCase() : 'en';
},

getUserLanguage: function()
{
	var lang = window.navigator.languages ? window.navigator.languages[0] : null;
	lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
	if( lang.indexOf( '-' ) !== -1 ) lang = lang.split( '-' )[0];
	if( lang.indexOf( '_' ) !== -1 ) lang = lang.split( '_' )[0];
	lang = ( $.inArray( lang, ['bs','hr','sr'] ) != -1 ) ? 'sh' : lang;
	return lang;
},

popup: function ( form )
{
	var x = screen.width / 2 - 700 / 2;
	var y = screen.height / 2 - 450 / 2;
	window.open( form.action, 'popup', 'height=485,width=700,left=' + x + ',top=' + y);
	return false;
},

scrollToggle: function( el, more, less, e )
{
	e.preventDefault();
	var body = $( 'html, body' );
	var hide = $( el + '-hide' );

	if( e.target.innerText.toLowerCase() === more.toLowerCase() )
	{
		e.target.innerHTML = less;
		hide.removeClass( 'hide' );
	}
	else if( e.target.innerText.toLowerCase() === less.toLowerCase() )
	{
		e.target.innerHTML = more;
		hide.addClass( 'hide' );
	}

	body.stop().animate( { scrollTop: $( el ).offset().top - ( $( 'nav.top-nav' ).outerHeight() + 50 ) }, 500 );
},

storageAvailable: function()
{
	if( typeof fb.data.storageAvailable == 'undefined' )
	{
		try
		{
			localStorage.setItem( 'fb', true );
			localStorage.removeItem( 'fb' );
			fb.data.storageAvailable = true;
		}
		catch( e )
		{
			fb.data.storageAvailable = false;
		}
    }
    
    return fb.data.storageAvailable;
},

tagsInputParse: function( obj, suggest, nameType )
{
	var type = ( nameType == 'surname' ) ? 's' : 'f';
	var matches = [];
	obj.forEach( function( o )
	{
		if( o.type == type )
			matches.push( o.name );
	});
	suggest( matches );
},

typeheadParse: function( data )
{
	var res = [];
	$.each( data, function( index, row )
	{
		if( fb.data.typheadType == 'a' || ( fb.data.typheadType == row.type ) || fb.data.typheadType == 'n' && $.inArray( row.type, ['f','s'] ) !== -1 )
			res.push( row );
	});
	return res;
},

typheadSource: function( query, syncResults, asyncResults )
{
	if( query.charCodeAt( 0 ) < 65 || query.charCodeAt( 0 ) > 1108 )
		return false;
	
	var type = this.name;
	fb.data.typheadType = type;
	if( typeof fb.data.typheadStorage[query] != 'undefined' )
		asyncResults( fb.typeheadParse( fb.data.typheadStorage[query] ) );
	var cacheUrl = '/data/cache/ps/' + query.toLowerCase() + '.json';
	
	$.getJSON( cacheUrl, function( data )
	{
		fb.data.typheadStorage[query] = data;
		asyncResults( fb.typeheadParse( data ) );
	});
},

typeheadSuggeestion: function( row )
{
	if( typeof row.path != 'undefined' && row.path )
		var path = ': <span>' + row.path + '</span>';
	else if( fb.data.typheadType == 'a' && row.type == 's' )
		 var path = ' <span>(' + fb.lang[fb.getSiteLanguage()].sn + ')</span>';
	else if( fb.data.typheadType == 'a' && row.type == 'f' )
		 var path = ' <span>(' + fb.lang[fb.getSiteLanguage()].fn + ')</span>';
	else
	 	 var path = '';
	var ico = '';
	if( typeof row.ico != 'undefined' && row.ico != null )
	{
		if( row.ico.substring( 0, 3 ) == 'fb-' )
			ico = '<i class="icon fb ' + row.ico + '"></i>';
		else
			ico = '<svg class="flag-icon"><use href="/assets/img.svg#' + row.ico + '"></use></svg>';
	}
	if( $.inArray( fb.getSiteLanguage(), row.langs ) == -1 && row.type != 'p' )
	{
		var siteLang = ( fb.getSiteLanguage() != 'en' ) ? fb.getSiteLanguage() + '/' : '' ;
		row.url = '/x/' + siteLang + row.url;
	}
	
	return '<div><a href="' + row.url + '">' + ico + row.name + path + '</a></div>';
},

typeheadTagsSuggeestion: function( row )
{
	return '<div><div class="mimic">' + row.name + '</div></div>';
},

initialiseAds: function()
{
	var loaders = $( '.3p-loader' );
	if( loaders.length == 0 ) return false;
	if( fb.data.ads === null )
	{
		window.setTimeout( fb.initialiseAds, 5 );
		return false;
	}
	else
	{
		loaders.each( function()
		{
			var el = $( this );
			var block = el.attr( 'data-block' );
			var block = ( block ) ? block : 'surtop';
			var vars = el.attr( 'data-vars' );
			fb.data.device = fb.getDeviceType();
			var blockDevice = el.attr( 'data-device' );
			var deviceSimple = fb.data.device;
			if( deviceSimple == 't' )
				deviceSimple = 'm';
			if( ( blockDevice === 'c' && deviceSimple !== 'c' ) || ( blockDevice === 'm' && deviceSimple === 'c' ) )
			{
				el.remove();
				return true;
			}
			if( typeof fb.data.country != 'undefined' || ( fb.storageAvailable() && localStorage.getItem( 'country' ) ) )
			{
				var iso = ( typeof localStorage.getItem( 'country' ) != 'undefined' ) ? localStorage.getItem( 'country' ) : fb.data.country;
				fb.prepareAd( el, block, iso, vars );
			}
			else
			{
				$.ajax(
				{
					type: 'GET',
					url: 'a?c=Browser&m=getISO',
					success: function( iso )
					{
						if( iso.length == 2 )
						{
							if( fb.storageAvailable() )
								localStorage.setItem( 'country', iso );
							fb.data.country = iso;
							fb.prepareAd( el, block, iso, vars );
						}
					},
					error: function( tt )
					{
						fb.data.country = 'xx';
						fb.prepareAd( el, block, iso, vars );
					}
				});
			}
		});
	}
},

prepareAd: function( el, block, iso, vars )
{
	var deviceSimple = fb.data.device;
	if( deviceSimple == 't' )
		deviceSimple = 'm';
	if( fb.data.ads === false && typeof fb.data.adBlocks[block].b != 'undefined' )
		var tpl = fb.data.adBlocks[block].b;
	else if( typeof fb.data.adBlocks[block][deviceSimple] != 'undefined' )
		var tpl = fb.data.adBlocks[block][deviceSimple];
	else
		var tpl = fb.data.adBlocks[block].f;
	
	/* if( tpl == 'gas-h' )
	{
		el.append( '<script async="true" src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script><ins class="adsbygoogle" data-ad-client="ca-pub-2095179440264685" data-ad-slot="3715594558" style="display:block;width:100%;max-height:90px;" data-ad-format="horizontal" data-full-width-responsive="true"></ins>' );
		( adsbygoogle = window.adsbygoogle || [] ).push( {} );
	}*/
	
	// Remove FreeStar blocks
	if( tpl.substring( 0, 3 ) == 'myh' && $.inArray( iso, ['au','ca','gb','ie','nz','us'] ) !== -1 )
	{
		el.find( 'div' ).remove();
		vars = ( vars ) ? '?' + vars : '';
		var device = 'device=' + fb.data.device;
		vars += ( vars ) ? '&' + device : '?' + device;
		var iso = 'iso=' + iso;
		vars += ( vars ) ? '&' + iso : '?' + iso;
		var ads = 'ads=' + ( ( fb.data.ads ) ? 1 : 0 );
		vars += ( vars ) ? '&' + ads : '?' + ads;
		var iclass = ( deviceSimple == 'm' ) ? 'iframe-mob' : 'iframe';
		el.append( '<iframe src="//' + window.location.host + '/data/tpl/3p/' + tpl + '.html' + vars + '" seamless class="' + iclass + '" scrolling="no">' );
	}
	else
		el.removeClass();
},

getDeviceType: function()
{
	if( fb.storageAvailable() && localStorage.getItem( 'device' ) )
		return localStorage.getItem( 'device' );
	else
	{
		if( window.matchMedia( 'only screen and (max-width: 991px)' ).matches )
			type = 'm';
		else if( window.matchMedia( 'only screen and (max-width: 1170px)' ).matches )
			type = 't';
		else
			type = 'c';
		
		/* var type = false;
		var agent = ( navigator.userAgent || navigator.vendor || window.opera ).toLowerCase();
		if( /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test( agent ) )
			type = 't';
		else if( /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test( agent )||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/.test( agent ) )
			type = 'm';
		else
			type = 'c'; */
		
		if( fb.storageAvailable() )
			localStorage.setItem( 'device', type );
		
		return type;
	}
},

tblSort: function( el, type, pos )
{
	var parentId = $( el ).parent().attr( 'data-parent' );
	var rowSelector = '#' + parentId + ' tbody tr';
	var bodySelector = '#' + parentId + ' table tbody';
	var funcs =
	{
		intAsc: function( el, pos )
		{
			function incidenceAsc( a, b )
			{
				return parseInt( $( a ).find( 'td' )[pos].innerHTML.replace( /,/g, '' ) ) > parseInt( $( b ).find( 'td' )[pos].innerHTML.replace( /,/g, '' ) ) ? 1 : -1;  
			};
			$( rowSelector ).sort( incidenceAsc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		intDesc: function( el, pos )
		{
			function incidenceDesc( a, b )
			{
				return parseInt( $( a ).find( 'td' )[pos].innerHTML.replace( /,/g, '' ) ) < parseInt( $( b ).find( 'td' )[pos].innerHTML.replace( /,/g, '' ) ) ? 1 : -1;  
			};
			$( rowSelector ).sort( incidenceDesc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		alphabeticAsc: function( el, pos )
		{
			function alphabeticAsc( a, b )
			{
				return $( $( b ).find( 'td' )[pos] ).text() > $( $( a ).find( 'td' )[0] ).text() ? -1 : 1; 
			};
			$( rowSelector ).sort( alphabeticAsc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		alphabeticDesc: function( el, pos )
		{
			function alphabeticDesc( a, b )
			{
				return $( $( b ).find( 'td' )[pos] ).text() < $( $( a ).find( 'td' )[0] ).text() ? -1 : 1;  
			};
			$( rowSelector ).sort( alphabeticDesc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		jurisdictionAsc: function( el )
		{
			function jurisdictionAsc( a, b )
			{
				return $( a ).find( 'span[title]' )[0].getAttribute( 'title' ) > $( b ).find( 'span[title]' )[0].getAttribute( 'title' ) ? 1 : -1;  
			};
			$( rowSelector ).sort( jurisdictionAsc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		jurisdictionDesc: function( el )
		{
			function jurisdictionDesc( a, b )
			{
				return $( a ).find( 'span[title]' )[0].getAttribute( 'title' ) < $( b ).find( 'span[title]' )[0].getAttribute( 'title' ) ? 1 : -1;  
			};
			$( rowSelector ).sort( jurisdictionDesc ).appendTo( $( bodySelector )[0] );
			this.postProcess( el );
		},
		postProcess: function( el )
		{
			$( '#' + parentId + '-sort' ).text( $( el ).text() );
			$( '#' + parentId + ' .read-more-link' ).remove();
		}
	}
	
	funcs[type]( el, pos );
},

nsSort:
{
	incidenceDesc: function( el )
	{
		$( '.reklama' ).remove();
		function incidenceDesc( a, b )
		{
			return parseInt( $( a ).find( 'h5.detail-value' )[0].innerHTML.replace( /,/g, '' ) ) < parseInt( $( b ).find( 'h5.detail-value' )[0].innerHTML.replace( /,/g, '' ) ) ? 1 : -1;  
		};
		$( '.search-result' ).sort( incidenceDesc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	},
	incidenceAsc: function( el )
	{
		$( '.reklama' ).remove();
		function incidenceAsc( a, b )
		{
			return parseInt( $( a ).find( 'h5.detail-value' )[0].innerHTML.replace( /,/g, '' ) ) > parseInt( $( b ).find( 'h5.detail-value' )[0].innerHTML.replace( /,/g, '' ) ) ? 1 : -1;  
		};
		$( '.search-result' ).sort( incidenceAsc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	},
	alphabeticDesc: function( el )
	{
		$( '.reklama' ).remove();
		function alphabeticDesc( a, b )
		{
			return $( $( b ).find( '.name' )[0] ).text() < $( $( a ).find( '.name' )[0] ).text() ? -1 : 1;  
		};
		$( '.search-result' ).sort( alphabeticDesc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	},
	alphabeticAsc: function( el )
	{
		$( '.reklama' ).remove();
		function alphabeticAsc( a, b )
		{
			return $( $( b ).find( '.name' )[0] ).text() < $( $( a ).find( '.name' )[0] ).text() ? 1 : -1;  
		};
		$( '.search-result' ).sort( alphabeticAsc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	},
	jurisdictionDesc: function( el )
	{
		$( '.reklama' ).remove();
		function jurisdictionDesc( a, b )
		{
			return $( a ).find( 'div[title]' )[0].getAttribute( 'title' ) < $( b ).find( 'div[title]' )[0].getAttribute( 'title' ) ? 1 : -1;  
		};
		$( '.search-result' ).sort( jurisdictionDesc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	},
	jurisdictionAsc: function( el )
	{
		$( '.reklama' ).remove();
		function jurisdictionAsc( a, b )
		{
			return $( a ).find( 'div[title]' )[0].getAttribute( 'title' ) > $( b ).find( 'div[title]' )[0].getAttribute( 'title' ) ? 1 : -1;  
		};
		$( '.search-result' ).sort( jurisdictionAsc ).appendTo( $( '.search-results' ) );
		$( '#sort-results' ).text( $( el ).text() );
	}
},

initialiseMYH: function( name )
{
	if( !name ) return false;
	$.ajax(
	{
		method: 'GET',
		url: '/data/cache/myh/' + name + '.json',
		dataType: 'json',
		success: function( data )
		{
			if( typeof data.t != 'undefined' && data.t != 0 )
				fb.insertMYHRecords( data );
			else
				fb.insertMYHTree( name );
		}
	});
},

myh: {
	b: { name: 'Birth Records', ico: 'birth' },
	m: { name: 'Marriage Records', ico: 'marriage' },
	d: { name: 'Death Records', ico: 'death' },
	c: { name: 'Census Records', ico: 'census' },
	w: { name: 'Military Records', ico: 'military' },
	i: { name: 'Immigration Records', ico: 'immigration' },
	n: { name: 'Newspaper Articles', ico: 'newspapers' }
},

insertMYHRecords: function( data )
{
	var closed = false;
	var tpl = '<div class="container-fluid ancestor-search"><div class="row"><div class="col-md-3 col-sm-12"><div class="title-md text-center records-found">We found records about <b>' + data.n + '</b></div></div>';
	tpl += '<div class="col-md-6 col-sm-12"><div id="myh-records" class="carousel" data-ride="carousel" data-interval="false"><div class="carousel-inner" role="listbox">';
	if( data.c.length == 0 )
		tpl += 'Discover the ancestry of the ' + data.n + ' family<div class="carousel-item">';
	$.each( data.c, function( i, cat )
	{
		var active = ( i == 0 ) ? ' active' : '';
		if( i % 2 == 0 )
		{
			closed = false;
			tpl += '<div class="carousel-item' + active + '">';
		}
		tpl += '<div><a href="' + cat.l + '"><svg><use href="/assets/img.svg#myh-' + fb.myh[cat.c].ico + '"></use></svg><span class="records">' + cat.t + ' ' + fb.myh[cat.c].name + '</span></a></div>';
		if( i & 1 )
		{
			closed = true;
			tpl += '</div>';
		}
	});
	if( closed == false )
		tpl += '</div>';
	if( data.c.length > 2 )
		tpl += '<a class="carousel-control-prev" data-target="#myh-records" role="button" data-slide="prev">&#8249;</a><a class="carousel-control-next" data-target="#myh-records" role="button" data-slide="next">&#8250;</a>';
	tpl += '</div></div></div><div class="col-md-3 col-sm-12 records-total"><div class="title-sm">Total records: <b>' + data.t + '</b></div><a class="btn btn-empty btn-small view-all" href="' + data.l + '">View All</a></div></div></div>';
	
	$( '.3p-loader' ).append( tpl ).removeClass( 'banner-h' ).removeClass( 'mx-auto' );
},

insertMYHTree: function( name )
{
	var tpl = '<div class="container-fluid ancestor-search"><div class="row"><div class="col-sm-12 col-md-3"><div class="title-md text-center records-found">Are you related to?</div></div><div class="col-sm-12 col-md-4 myh-tree"><svg><use href="/assets/img.svg#myh-tree"></use></svg></div><div class="col-sm-12 col-md-5"><div class="title-sm text-center">Discover your connection to the ' + name + ' family</div><a class="btn btn-empty btn-small view-all" href="#">Start Your Family Tree</a></div></div></div>';
	$( '.3p-loader' ).append( tpl ).removeClass( 'banner-h' ).removeClass( 'mx-auto' );
},

insetMYHSearch: function()
{
	var tpl = '<div class="container-fluid ancestor-search transparent"><div class="row"  style="background: #eee;"><div class="col-md-4 col-sm-12 myh-splash"><svg><use href="/assets/img.svg#myh-ancestors"></use></svg></div><div class="col-md-8 col-sm-12 p-0"><div class="title" style="line-height: 24px;">Discover your ancestors</div><div class="subtitle m-0">Simply enter a name — we’ll do the rest!</div><form><input class="form-control" type="text" placeholder="First Name" /><input class="form-control" type="text" placeholder="Last Name" /><a class="btn btn-empty btn-small btn-inline" href="#">Start Now!</a></form></div></div></div>';
	$( '.3p-loader' ).append( tpl ).removeClass( 'banner-h' ).removeClass( 'mx-auto' );
},

numberFormat: function ( num, digits )
{
	if( num < 1 ) return num;
	const lookup = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup.slice().reverse().find( function( item ) { return num >= item.value; });
	return item ? ( num / item.value ).toFixed( digits ).replace( rx, "$1" ) + item.symbol : "0";
	}
}

$( window ).on( 'load', function() {
    fb.initialiseAds();
    fb.initialiseTypheads();
});
fb.initialiseSurnameSubmission();
fb.initialiseStorage();
fb.initialiseLayout();
fb.initialiseAccount();
fb.initialiseLogin();
fb.initialiseRegister();
fb.initialiseLanguageSelection();
fb.initialiseEmailSubscribe();
fb.initialiseSearch();
fb.initialiseTextareas();
fb.initialiseModals();
fb.initialiseTranslationSuggestion();
//fb.initialiseOutLinks();
fb.initialiseLightbox();
fb.initialiseCitations();

( function( document, history, location )
{
	var HISTORY_SUPPORT = !!( history && history.pushState );
	
	var anchorScrolls = {
		ANCHOR_REGEX: /^#[^ ]+$/,
		OFFSET_HEIGHT_PX: 120,
		
		/**
		* Establish events, and fix initial scroll position if a hash is provided.
		*/
		init: function()
		{
			this.scrollToCurrent();
			window.addEventListener( 'hashchange', this.scrollToCurrent.bind( this ) );
			document.body.addEventListener( 'click', this.delegateAnchors.bind( this ) );
		},
		
		/**
		* Return the offset amount to deduct from the normal scroll position.
		* Modify as appropriate to allow for dynamic calculations
		*/
		getFixedOffset: function()
		{
			return this.OFFSET_HEIGHT_PX;
		},
		
		/**
		* If the provided href is an anchor which resolves to an element on the
		* page, scroll to it.
		* @param  {String} href
		* @return {Boolean} - Was the href an anchor.
		*/
		scrollIfAnchor: function( href, pushToHistory )
		{
			var match, rect, anchorOffset;
			
			if( !this.ANCHOR_REGEX.test( href ) )
			{
				return false;
			}
		
			match = document.getElementById( href.slice( 1 ) );
			
			if( match )
			{
				rect = match.getBoundingClientRect();
				anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
				window.scrollTo( window.pageXOffset, anchorOffset );
			
				// Add the state to history as-per normal anchor links
				if( HISTORY_SUPPORT && pushToHistory )
				{
					history.pushState( {}, document.title, location.pathname + href );
				}
			}
			
			return !!match;
		},
		
		/**
		* Attempt to scroll to the current location's hash.
		*/
		scrollToCurrent: function()
		{
			this.scrollIfAnchor( window.location.hash );
		},
		
		/**
		* If the click event's target was an anchor, fix the scroll position.
		*/
		delegateAnchors: function( e )
		{
			var elem = e.target;
			if( elem.nodeName === 'A' && this.scrollIfAnchor(elem.getAttribute('href'), true) )
			{
				e.preventDefault();
			}
		}
	};

	window.addEventListener( 'DOMContentLoaded', anchorScrolls.init.bind( anchorScrolls ) );
} )( window.document, window.history, window.location );